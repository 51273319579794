'use client'

import React from "react";
import {motion} from "framer-motion";

const icon = {
    loop: {
        opacity: [0, 0.5, 1, 0],
        pathLength: [0, 1],
        strokeWidth: ["2.30894", "2.30894", "2.30894"],
        transition: {
            duration: 2.5,
            ease: "easeInOut",
            times: [0, 0.1, 0.2, 0.3, 0.5, 0.7, 0.9, 1],
            repeat: Infinity,
        },
    },
};

const rects = {
    loop: {
        x: [7, -5],
        opacity: [0, 0.5, 1, 0],
        transition: {
            delay: 1.2,
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.5, 1],
            repeat: Infinity,
        },
    },
};

const rects2 = {
    loop: {
        x: [5, -5],
        opacity: [0, 0.5, 1, 0],
        transition: {
            delay: 1.5,
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.5, 1],
            repeat: Infinity,
        },
    },
};

const rects3 = {
    loop: {
        x: [3, -5],
        opacity: [0, 0.5, 1, 0],
        transition: {
            delay: 0.8,
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.5, 1],
            repeat: Infinity,
        },
    },
};

export function Loader({size = 35}) {
    return (
        <>
            <svg width={size} height={size} viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <motion.path
                    variants={icon}
                    initial="loop"
                    animate="loop"
                    d="M30.1263 10.2076L13.5681 2.00193L12.9755 1.72655L12.3828 1.45117L13.0018 3.05145L16.0747 10.2076M30.1263 10.2076L12.3828 19.5303L16.0747 10.2076M30.1263 10.2076H16.0747"
                    stroke="#000" // Set the color to black
                />
                <motion.rect
                    variants={rects}
                    initial="loop"
                    animate="loop"
                    x="0.158691"
                    y="5.81348"
                    width="9.42743"
                    height="2.01377"
                    rx="1.00689"
                    fill="#000" // Set the color to black
                />
                <motion.rect
                    variants={rects2}
                    initial="loop"
                    animate="loop"
                    x="2.51611"
                    y="9.36426"
                    width="7.07057"
                    height="2.01377"
                    rx="1.00689"
                    fill="#000" // Set the color to black
                />
                <motion.rect
                    variants={rects3}
                    initial="loop"
                    animate="loop"
                    x="5.02197"
                    y="12.9141"
                    width="4.56455"
                    height="2.01377"
                    rx="1.00689"
                    fill="#000" // Set the color to black
                />
            </svg>
        </>
    );
}
